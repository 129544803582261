<template>
  <v-container fluid>
    <FilterDrawer
      current-component="search-clients-filter"
      @filter="filterClients"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.company_name }}</td>
        <td>{{ item.trading_name }}</td>
        <td>
          <ContactInfo
            v-if="item.client_contact"
            :name="JSON.parse(item.client_contact)[0].name"
            :phone-one="JSON.parse(item.client_contact)[0].phone_number"
            :phone-two="JSON.parse(item.client_contact)[0].cel_number"
            :email="JSON.parse(item.client_contact)[0].email"
          />
          <span v-else>-</span>
        </td>
        <td>{{ item.agency }}</td>
        <td>{{ item.seller }}</td>
        <td>
          <StatusChip :current-status="item.status" />
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import ContactInfo from '@/components/data-table/ContactInfo'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
    ContactInfo,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'Consultar Clientes',
      filter: 'search-clients-filter',
      totalPages: 1,
      totalRegister: 1,
      headerButtons: {
        filter: true,
        new: false,
      },
      items: [],
      isLoading: true,
      headers: [
        { text: 'Razão Social', value: 'company_name' },
        { text: 'Nome Fantasia', value: 'trading_name' },
        { text: 'Contato', value: 'client_contact' },
        { text: 'Agência', value: 'agency' },
        { text: 'Vendedor', value: 'seller', align: 'center' },
        { text: 'Status', value: 'status' },
      ],
    },
  }),
  created: function () {
    this.getClients()
  },
  methods: {
     setCurrentPage(page) {
      this.getClients(page)
    },
    search(search) {
      this.currentSearch = search
      this.getClients()
    },
    getClients(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('people/type/clients', {
          params: {
            page,
            search: this.currentSearch,
          },
        })
        .then((res) => {
          this.dataTable.items = res.data.data
          this.dataTable.totalPages = res.data.last_page;
          this.dataTable.totalRegister = res.data.total;
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterClients(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('people/filter/client', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getClients()
    },
  },
}
</script>
